.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker-popper {
    z-index: 10000;
}

.react-datepicker__header > .react-datepicker__header__dropdown {
    margin: 0.6rem 0 !important;
}

.react-datepicker__header
    > .react-datepicker__header__dropdown
    > .react-datepicker__month-dropdown-container
    > .react-datepicker__month-select,
.react-datepicker__header
    > .react-datepicker__header__dropdown
    > .react-datepicker__year-dropdown-container
    > .react-datepicker__year-select {
    border: 1px solid black;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
}

.MuiPickersCalendarHeader-labelContainer {
    margin: 0 !important;
}
